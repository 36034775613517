import React from 'react';

export const PushPin = () => {
	return (
		<svg preserveAspectRatio="none" viewBox="0 0 24 24">
			<path fillRule="evenodd" d="M19.083 1c0 1.018-1.424 1.907-3.541 2.382V11c2.926.652 4.958 2.086 4.958 3.751h-7.792V23h-1.416v-8.25H3.5c0-1.665 2.032-3.1 4.958-3.751V3.382C6.341 2.907 4.917 2.018 4.917 1h14.166z" />
		</svg>
	);
}

export const WindGust = () => {
	return (
		<svg version="1.1" x="0px" y="0px"
			viewBox="0 0 331.309 331.309" xmlSpace="preserve">
			<g>
				<g>
					<g>
						<path d="M49.425,143.993h138.344c19.952,0,36.184-16.232,36.184-36.184c0-19.952-16.231-36.185-36.184-36.185
				c-19.953,0-36.185,16.232-36.185,36.185c0,0.737,0.099,1.45,0.266,2.136c0.96,3.938,4.501,6.864,8.734,6.864c4.971,0,9-4.029,9-9
				c0-10.027,8.157-18.185,18.185-18.185c10.026,0,18.184,8.158,18.184,18.185c0,10.027-8.157,18.184-18.184,18.184H86.227H49.425
				c-4.971,0-9,4.029-9,9S44.454,143.993,49.425,143.993z"/>
						<path d="M270.532,187.315H132.188c-4.971,0-9,4.029-9,9s4.029,9,9,9h36.803h101.541c10.027,0,18.185,8.157,18.185,18.184
				c0,10.027-8.157,18.185-18.185,18.185c-10.028,0-18.185-8.158-18.185-18.185c0-4.971-4.029-9-9-9
				c-4.233,0-7.774,2.926-8.734,6.864c-0.167,0.686-0.266,1.399-0.266,2.136c0,19.952,16.232,36.185,36.185,36.185
				s36.185-16.232,36.185-36.185S290.484,187.315,270.532,187.315z"/>
						<path d="M282.81,79.094c-26.743,0-48.5,21.756-48.5,48.499c0,4.971,4.029,9,9,9s9-4.029,9-9c0-16.817,13.683-30.499,30.5-30.499
				c16.817,0,30.499,13.682,30.499,30.499c0,16.817-13.682,30.5-30.499,30.5H9c-4.971,0-9,4.029-9,9c0,4.971,4.029,9,9,9h273.81
				c26.742,0,48.499-21.757,48.499-48.5C331.309,100.849,309.552,79.094,282.81,79.094z"/>
						<path d="M104.743,187.315H87.785c-4.971,0-9,4.029-9,9s4.029,9,9,9h16.958c4.971,0,9-4.029,9-9S109.714,187.315,104.743,187.315z
				"/>
						<path d="M21.987,143.993h3.334c4.971,0,9-4.029,9-9s-4.029-9-9-9h-3.334c-4.971,0-9,4.029-9,9S17.017,143.993,21.987,143.993z" />
					</g>
				</g>
			</g>
		</svg>
	);
}

export const LegendIcon = () => {
	return (
		<svg version="1.1" id="Layer_1" x="0px" y="0px"
			viewBox="0 0 512 512" xmlSpace="preserve">
			<path className="outline-path" d="M496.3,141.692c9.686,4.837,15.7,14.57,15.7,25.386c0,10.827-6.014,20.561-15.688,25.398
	c-0.012,0-0.012,0-0.012,0l-38.12,19.066l38.12,19.066c9.686,4.837,15.7,14.57,15.7,25.398c0,10.816-6.014,20.549-15.688,25.386
	l-38.132,19.066l38.12,19.066c9.674,4.825,15.688,14.558,15.7,25.374c0,10.828-6.014,20.572-15.688,25.409l-215.22,107.604
	c-7.85,3.931-16.477,5.896-25.092,5.896c-8.615,0-17.23-1.965-25.092-5.885L15.7,370.305C6.014,365.468,0,355.723,0,344.896
	c0.012-10.816,6.026-20.549,15.7-25.386l38.12-19.054L15.7,281.39C6.014,276.553,0,266.82,0,256.004
	c0-10.828,6.014-20.561,15.688-25.398l38.132-19.066L15.7,192.475C6.014,187.638,0,177.905,0,167.078
	c0-10.816,6.014-20.549,15.688-25.386l215.22-107.604c15.712-7.85,34.471-7.862,50.183-0.012L496.3,141.692z M488.462,167.089
	c0-1.13-0.353-3.178-2.695-4.355L270.57,55.131c-4.566-2.271-9.568-3.413-14.57-3.413s-10.004,1.142-14.57,3.425L26.221,162.735
	c-2.33,1.177-2.683,3.225-2.683,4.355c0,1.118,0.353,3.166,2.695,4.343L241.43,279.036c9.121,4.555,20.019,4.555,29.14-0.012
	l215.197-107.592C488.109,170.255,488.462,168.207,488.462,167.089z M488.462,256.004c0-1.13-0.353-3.178-2.695-4.355
	l-53.902-26.951l-150.773,75.381c-7.85,3.931-16.477,5.896-25.092,5.896c-8.615,0-17.23-1.965-25.092-5.885L80.135,224.699
	L26.221,251.65c-2.33,1.177-2.683,3.225-2.683,4.355c0,1.118,0.353,3.166,2.695,4.343L241.43,367.951
	c9.121,4.555,20.019,4.555,29.14-0.012l215.209-107.592C488.109,259.17,488.462,257.122,488.462,256.004z M488.462,344.908
	c0-1.118-0.353-3.178-2.683-4.331l-53.914-26.963l-150.773,75.381c-7.85,3.931-16.477,5.896-25.092,5.896
	c-8.615,0-17.23-1.965-25.092-5.885L80.135,313.614l-53.914,26.951c-2.33,1.165-2.683,3.225-2.683,4.343s0.353,3.178,2.695,4.355
	L241.43,456.866c9.121,4.555,20.019,4.555,29.14-0.012l215.209-107.592C488.109,348.085,488.462,346.026,488.462,344.908z"/>
			<path d="M485.767,162.735c2.342,1.177,2.695,3.225,2.695,4.355c0,1.118-0.353,3.166-2.695,4.343
	L270.57,279.025c-9.121,4.566-20.019,4.566-29.14,0.012L26.233,171.432c-2.342-1.177-2.695-3.225-2.695-4.343
	c0-1.13,0.353-3.178,2.683-4.355L241.43,55.142c4.566-2.283,9.568-3.425,14.57-3.425c5.002,0,10.004,1.142,14.57,3.413
	L485.767,162.735z"/>
			<path d="M485.767,251.65c2.342,1.177,2.695,3.225,2.695,4.355c0,1.118-0.353,3.166-2.683,4.343L270.57,367.94
	c-9.121,4.566-20.019,4.566-29.14,0.012L26.233,260.347c-2.342-1.177-2.695-3.225-2.695-4.343c0-1.13,0.353-3.178,2.683-4.355
	l53.914-26.951l150.773,75.392c7.862,3.919,16.477,5.884,25.092,5.884c8.615,0,17.242-1.965,25.092-5.896l150.773-75.381
	L485.767,251.65z"/>
			<path d="M485.779,340.577c2.33,1.153,2.683,3.213,2.683,4.331s-0.353,3.178-2.683,4.355L270.57,456.855
	c-9.121,4.566-20.019,4.566-29.14,0.012L26.233,349.262c-2.342-1.177-2.695-3.236-2.695-4.355s0.353-3.178,2.683-4.343
	l53.914-26.951l150.773,75.392c7.862,3.919,16.477,5.885,25.092,5.885c8.615,0,17.242-1.965,25.092-5.896l150.773-75.381
	L485.779,340.577z"/>
		</svg>
	);
}

export const Clear = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
			<path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
			<path d="M0 0h24v24H0z" fill="none" />
		</svg>
	)
}

export const Done = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
			<path fill="none" d="M0 0h24v24H0z" /><path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
		</svg>
	)
}

export const MagnifyingGlass = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="-2 -2 30 30" space="preserve" fill="#1A1A1A">
			<g>
				<path d="m 16.069954,0.8723014 c -4.939093,0 -8.9564871,4.0159906 -8.9564871,8.9536837 0,1.7971799 0.5392936,3.4738939 1.4567945,4.8788609 l -7.082263,7.121484 c -0.63454591,0.635947 -0.49026716,1.808387 0.3193742,2.615226 0.8096417,0.808242 1.9806804,0.948317 2.6110243,0.312371 l 7.1579061,-7.195724 c 1.322321,0.770419 2.853356,1.220064 4.493651,1.220064 4.93629,0 8.950881,-4.014589 8.950881,-8.9522819 0,-4.9376931 -4.014591,-8.9536837 -8.950881,-8.9536837 z m 0,14.6631976 c -3.148917,0 -5.712316,-2.559195 -5.712316,-5.7095139 0,-3.1475166 2.563399,-5.7081133 5.712316,-5.7081133 3.146114,0 5.708111,2.5605967 5.708111,5.7081133 0,3.1503189 -2.561997,5.7095139 -5.708111,5.7095139 z"></path>
			</g>
		</svg>
	)
}

export const EmptyCheck = () => {
	return (
		<svg colorInterpolationFilters="sRGB" fill="none" fillRule="evenodd" height="1.08333in" strokeLinecap="square" strokeMiterlimit="3" viewBox="0 0 78 78" width="1.08333in" xmlns="http://www.w3.org/2000/svg">
			<rect fill="none" height="72" rx="5.76" stroke="#262626" strokeLinecap="round" strokeLinejoin="round" strokeWidth="6" width="72" x="3" y="3" />
		</svg>
	)
}

export const Checked = () => {
	return (
		<svg colorInterpolationFilters="sRGB" fill="none" fillRule="evenodd" height="1.08333in" strokeLinecap="square" strokeMiterlimit="3" viewBox="0 0 78 78" width="1.08333in" xmlns="http://www.w3.org/2000/svg">
			<g strokeLinecap="round" strokeLinejoin="round">
				<rect fill="#262626" height="72" rx="5.76" stroke="#262626" strokeWidth="6" width="72" x="3" y="3" /><path d="m0 78h30.32v-53.86h-8.37v45.5h-21.95z" fill="#fff" stroke="#fff" strokeWidth=".75" transform="matrix(.70710678 .70710678 -.70710678 .70710678 65.1051 -17.0429)" />
			</g>
		</svg>
	)
}

export const IndeterminateCheck = () => {
	return (
		<svg colorInterpolationFilters="sRGB" fill="none" fillRule="evenodd" height="1.08333in" strokeLinecap="square" strokeMiterlimit="3" viewBox="0 0 78 78" width="1.08333in" xmlns="http://www.w3.org/2000/svg">
			<rect fill="none" height="72" rx="5.76" stroke="#262626" strokeLinecap="round" strokeLinejoin="round" strokeWidth="6" width="72" x="3" y="3" /><path d="m18.255 18.255h41.49v41.49h-41.49z" fill="#262626" />
		</svg>
	)
}

export const EmptyRadio = () => {
	return (
		<svg colorInterpolationFilters="sRGB" fill="none" fillRule="evenodd" height="1.08333in" strokeLinecap="square" strokeMiterlimit="3" viewBox="0 0 78.5625 78" width="1.09115in" xmlns="http://www.w3.org/2000/svg">
			<ellipse cx="39.2813" cy="39" fill="none" rx="36.2813" ry="36" stroke="#262626" strokeLinecap="round" strokeLinejoin="round" strokeWidth="6" />
		</svg>
	)
}

export const FillRadio = () => {
	return (
		<svg colorInterpolationFilters="sRGB" fill="none" fillRule="evenodd" height="1.08333in" strokeLinecap="square" strokeMiterlimit="3" viewBox="0 0 78.5625 78" width="1.09115in" xmlns="http://www.w3.org/2000/svg">
			<ellipse cx="39.2813" cy="39" fill="none" rx="36.2813" ry="36" stroke="#262626" strokeLinecap="round" strokeLinejoin="round" strokeWidth="6" /><ellipse cx="39" cy="39" fill="#262626" rx="21.2344" ry="21.0698" />
		</svg>
	)
}

export const AddPhoto = () => {
	return (
		<svg width="60" height="60" viewBox="0 0 60 60">
			<g className="b">
				<path className="a" d="M7.5,8.5V1h5V8.5H20v5H12.5V21h-5V13.5H0v-5Zm7.5,15V16h7.5V8.5H40l4.575,5H52.5a5.015,5.015,0,0,1,5,5v30a5.015,5.015,0,0,1-5,5h-40a5.015,5.015,0,0,1-5-5v-25ZM32.5,46A12.5,12.5,0,1,0,20,33.5,12.5,12.5,0,0,0,32.5,46Zm-8-12.5a8,8,0,1,0,8-8,7.991,7.991,0,0,0-8,8Z" transform="translate(0 1.5)" />
			</g>
		</svg>
	)
}

export const Trashcan = () => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24">
			<path className="a" d="M6,19a2.006,2.006,0,0,0,2,2h8a2.006,2.006,0,0,0,2-2V7H6ZM19,4H15.5l-1-1h-5l-1,1H5V6H19Z" />
		</svg>
	)
}

export const MapPin = () => {
	return (
		<svg width="60" height="60" viewBox="0 0 60 60">
			<path className="a" d="M20.82,2C12.074,2,5,9.615,5,19.03,5,31.8,20.82,50.656,20.82,50.656S36.641,31.8,36.641,19.03C36.641,9.615,29.567,2,20.82,2Zm0,23.112a5.88,5.88,0,0,1-5.65-6.082,5.88,5.88,0,0,1,5.65-6.082,5.88,5.88,0,0,1,5.65,6.082A5.88,5.88,0,0,1,20.82,25.112Z" transform="translate(9.18 3.672)" />
		</svg>
	)
}

export const BackArrow = () => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24">
			<path d="M0 0h24v24H0z" fill="none" />
			<path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" />
		</svg>
	)
}

export const SkinnyArrow = () => {
	return (
		<svg viewBox="0 0 24 24">
			<path d="M16.01 11H4v2h12.01v3L20 12l-3.99-4z"></path>
		</svg>
	)
}

export const DataEntry = () => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24">
			<path d="M0 0h24v24H0z" fill="none" />
			<path d="M14 10H2v2h12v-2zm0-4H2v2h12V6zm4 8v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zM2 16h8v-2H2v2z" />
		</svg>
	)
}

export const Crosshair = () => {
	return (
		<svg fill="none" fillRule="evenodd" height="1.16667in" strokeLinecap="square" strokeMiterlimit="3" viewBox="0 0 84 84" width="1.16667in">
			<g stroke="#f00" strokeLinecap="butt" strokeWidth="6">
				<path d="m0 84h27" transform="matrix(0 1 -1 0 126 6)" />
				<path d="m0 84h27" transform="matrix(0 1 -1 0 126 51)" />
				<path d="m0 84h27" transform="matrix(-1 0 0 -1 78 126)" />
				<path d="m0 84h27" transform="matrix(-1 0 0 -1 33 126)" />
			</g>
			<ellipse cx="42" cy="42" fill="#f00" rx="2.25" ry="2.25" />
		</svg>
	)
}

export const YellowCrosshair = () => {
	return (
		<svg fill="none" fillRule="evenodd" height="1.16667in" strokeLinecap="square" strokeMiterlimit="3" viewBox="0 0 84 84" width="1.16667in">
			<g stroke="yellow" strokeLinecap="butt" strokeWidth="6">
				<path d="m0 84h27" transform="matrix(0 1 -1 0 126 6)" />
				<path d="m0 84h27" transform="matrix(0 1 -1 0 126 51)" />
				<path d="m0 84h27" transform="matrix(-1 0 0 -1 78 126)" />
				<path d="m0 84h27" transform="matrix(-1 0 0 -1 33 126)" />
			</g>
			<ellipse cx="42" cy="42" fill="yellow" rx="2.25" ry="2.25" />
		</svg>
	)
}

export const ReSelect = () => {
	return (
		<svg height="24" viewBox="0 0 24 24" width="24">
			<path d="M0 0h24v24H0z" fill="none" />
			<path d="M7.11 8.53L5.7 7.11C4.8 8.27 4.24 9.61 4.07 11h2.02c.14-.87.49-1.72 1.02-2.47zM6.09 13H4.07c.17 1.39.72 2.73 1.62 3.89l1.41-1.42c-.52-.75-.87-1.59-1.01-2.47zm1.01 5.32c1.16.9 2.51 1.44 3.9 1.61V17.9c-.87-.15-1.71-.49-2.46-1.03L7.1 18.32zM13 4.07V1L8.45 5.55 13 10V6.09c2.84.48 5 2.94 5 5.91s-2.16 5.43-5 5.91v2.02c3.95-.49 7-3.85 7-7.93s-3.05-7.44-7-7.93z" />
		</svg>
	)
}

export const BlueAutoFill = () => {
	return (
		<svg colorInterpolationFilters="sRGB" fill="none" fillRule="evenodd" strokeLinecap="square" strokeMiterlimit="3" viewBox="0 0 216.75 216.75" xmlns="http://www.w3.org/2000/svg">
			<ellipse cx="108.375" cy="108.375" fill="#386a94" rx="108" ry="108" />
			<ellipse cx="108.375" cy="107.7187" fill="#fff" rx="81" ry="81" />
			<g fill="#000">
				<rect height="24.4586" rx="10.8" width="88.051" x="77.2817" y="95.4844" />
				<rect height="24.4586" rx="10.8" transform="matrix(.70710678 .70710678 -.70710678 .70710678 200.209 -45.542)" width="75.4056" y="192.291" />
				<rect height="24.4586" rx="10.8" transform="matrix(-.70710678 .70710678 -.70710678 -.70710678 253.538 207.66)" width="75.4056" y="192.291" />
			</g>
		</svg>
	)
}

export const PlusButton = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" className="plus-button-svg" >
			<path d="M0 0h24v24H0z" fill="none" />
			<path d="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4V7zm-1-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
		</svg>
	)
}

export const MinusButton = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
			<path d="M0 0h24v24H0z" fill="none" />
			<path d="M7 11v2h10v-2H7zm5-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
		</svg>
	)
}

export const PanTool = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24" viewBox="2 0 24 26" width="24">
			<g><rect fill="none" height="24" width="24" />
			</g><g><g><g>
				<path d="M23,5.5V20c0,2.2-1.8,4-4,4h-7.3c-1.08,0-2.1-0.43-2.85-1.19L1,14.83c0,0,1.26-1.23,1.3-1.25 c0.22-0.19,0.49-0.29,0.79-0.29c0.22,0,0.42,0.06,0.6,0.16C3.73,13.46,8,15.91,8,15.91V4c0-0.83,0.67-1.5,1.5-1.5S11,3.17,11,4v7 h1V1.5C12,0.67,12.67,0,13.5,0S15,0.67,15,1.5V11h1V2.5C16,1.67,16.67,1,17.5,1S19,1.67,19,2.5V11h1V5.5C20,4.67,20.67,4,21.5,4 S23,4.67,23,5.5z" />
			</g></g></g>
		</svg>
	)
}

export const FindMe = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
			<path d="M18 8c0-3.31-2.69-6-6-6S6 4.69 6 8c0 4.5 6 11 6 11s6-6.5 6-11zm-8 0c0-1.1.9-2 2-2s2 .9 2 2-.89 2-2 2c-1.1 0-2-.9-2-2zM5 20v2h14v-2H5z" />
			<path d="M0 0h24v24H0z" fill="none" />
		</svg>
	)
}

export const MiniMap = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
			<path d="M0 0h24v24H0z" fill="none" />
			<path d="M12.01 5.5L10 8h4l-1.99-2.5zM18 10v4l2.5-1.99L18 10zM6 10l-2.5 2.01L6 14v-4zm8 6h-4l2.01 2.5L14 16zm7-13H3c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16.01H3V4.99h18v14.02z" />
		</svg>
	)
}

export const Calendar = () => {
	return (
		<svg x="0px" y="0px" width="36.447px" height="36.447px" viewBox="0 0 36.447 36.447">
			<g>
				<g>
					<path d="M30.224,3.948h-1.098V2.75c0-1.517-1.197-2.75-2.67-2.75c-1.474,0-2.67,1.233-2.67,2.75v1.197h-2.74V2.75
			c0-1.517-1.197-2.75-2.67-2.75c-1.473,0-2.67,1.233-2.67,2.75v1.197h-2.74V2.75c0-1.517-1.197-2.75-2.67-2.75
			c-1.473,0-2.67,1.233-2.67,2.75v1.197H6.224c-2.343,0-4.25,1.907-4.25,4.25v24c0,2.343,1.907,4.25,4.25,4.25h24
			c2.344,0,4.25-1.907,4.25-4.25v-24C34.474,5.855,32.567,3.948,30.224,3.948z M25.286,2.75c0-0.689,0.525-1.25,1.17-1.25
			c0.646,0,1.17,0.561,1.17,1.25v4.896c0,0.689-0.524,1.25-1.17,1.25c-0.645,0-1.17-0.561-1.17-1.25V2.75z M17.206,2.75
			c0-0.689,0.525-1.25,1.17-1.25s1.17,0.561,1.17,1.25v4.896c0,0.689-0.525,1.25-1.17,1.25s-1.17-0.561-1.17-1.25V2.75z M9.125,2.75
			c0-0.689,0.525-1.25,1.17-1.25s1.17,0.561,1.17,1.25v4.896c0,0.689-0.525,1.25-1.17,1.25s-1.17-0.561-1.17-1.25V2.75z
			 M31.974,32.198c0,0.965-0.785,1.75-1.75,1.75h-24c-0.965,0-1.75-0.785-1.75-1.75v-22h27.5V32.198z"/>
				</g>
			</g>
		</svg>
	)
}

export const PowerButton = () => {
	return (
		<svg x="0px" y="0px" viewBox="0 0 512 512" xmlSpace="preserve">
			<g id="XMLID_5_">
				<path id="XMLID_9_" d="M283.3,0.5h-56.5v284.2h56.5V0.5z M420.5,61.8l-39.9,39.9c44.8,36,74,91.5,74,153.8
		c0,110-88.6,198.6-198.6,198.6S56.5,365.5,56.5,255.5c0-62.3,28.2-117.8,73-153.8L89.6,61.8C34.1,108.5,0,177.6,0,255.5
		c0,141.1,114.9,256,256,256s256-114.9,256-256C511,177.6,476,108.5,420.5,61.8z"/>
			</g>
		</svg>
	)
}

export const TaskIcon = () => {
	return (
		<svg width="50" height="50" viewBox="0 0 18.635 15.3657" xmlSpace="preserve" fill="#FFFFFF" stroke="#FFFFFF" strokeLinecap="butt" strokeWidth="0.25">
			<g>
				<path d="M13.28 5.34 A1.93952 1.93952 -180 0 0 15.22 3.41 A1.93952 1.93952 -180 0 0 13.28 1.47 A1.94024 1.94024 -180
						        0 0 11.34 3.41 A1.94061 1.94061 -180 0 0 13.28 5.34 ZM13.28 0.5 A2.90668 2.90668 0 0 1 16.19 3.41 A2.90668
						        2.90668 0 0 1 13.28 6.31 A2.90656 2.90656 0 0 1 10.37 3.41 A2.90656 2.90656 0 0 1 13.28 0.5 ZM4.85 14.25
						        A3.38725 3.38725 -180 0 0 8.73 12.31 A4.26121 4.26121 -180 0 0 6.87 8.55 A14254.4 14254.4 -180 0 0 4.85
						        12.51 A7241.37 7241.37 0 0 1 2.84 8.55 A4.26168 4.26168 -180 0 0 0.98 12.31 A3.38687 3.38687 -180 0 0 4.85
						        14.25 ZM6.77 7.36 A5.10704 5.10704 0 0 1 9.7 12.31 A3.87035 3.87035 0 0 1 4.85 15.22 A3.87004 3.87004 0
						        0 1 0.01 12.31 A5.10919 5.10919 0 0 1 2.94 7.36 A3.47894 3.47894 -180 0 0 3.47 7.65 L4.85 10.37 L6.24 7.65
						        A3.42577 3.42577 -180 0 0 6.77 7.36 ZM15.19 6.2 A5.10704 5.10704 0 0 1 18.12 11.16 A3.87035 3.87035 0 0
						        1 13.28 14.06 A7.71507 7.71507 0 0 1 10.04 13.48 A2.94106 2.94106 -180 0 0 10.25 12.5 A6.678 6.678 -180
						        0 0 13.28 13.1 A3.38725 3.38725 -180 0 0 17.16 11.16 A4.2602 4.2602 -180 0 0 15.29 7.4 A9116.22 9116.22
						        -180 0 0 13.28 11.36 A10146.1 10146.1 0 0 1 11.27 7.4 A3.86169 3.86169 -180 0 0 9.67 9.48 A5.48459 5.48459
						        -180 0 0 9.05 8.44 A4.55847 4.55847 0 0 1 11.36 6.2 A3.47894 3.47894 -180 0 0 11.89 6.49 L13.28 9.22 L14.67
						        6.49 A3.42577 3.42577 -180 0 0 15.19 6.2 ZM4.85 6.5 A1.93915 1.93915 -180 0 0 6.79 4.56 A1.93952 1.93952
						        -180 0 0 4.85 2.62 A1.94024 1.94024 -180 0 0 2.92 4.56 A1.94061 1.94061 -180 0 0 4.85 6.5 ZM4.85 1.65 A2.90668
						        2.90668 0 0 1 7.76 4.56 A2.90668 2.90668 0 0 1 4.85 7.47 A2.90656 2.90656 0 0 1 1.95 4.56 A2.90656 2.90656
						        0 0 1 4.85 1.65 Z"></path>
			</g>
		</svg>
	)
}

export const PaperMagnifyingGlass = () => {
	return (
		<svg width="50" height="50" viewBox="0 0 18.635 18.6337" xmlSpace="preserve" fill="#FFFFFF" stroke="#FFFFFF" strokeLinecap="butt" strokeWidth="0.25">
			<g>
				<path d="M6.1 11.18 A4.41396 4.41396 -180 0 0 10.51 15.6 A4.38853 4.38853 -180 0 0 13.24 14.65 L13.44 15 L16.38 18.37
						 a0.703929 0.703929 -180 0 0 1.06991 0.0265534 L17.93 17.9 a0.746467 0.746467 -180 0 0 -0.0660597 -1.09776
						 L14.34 14.1 L13.98 13.9 A4.38707 4.38707 -180 0 0 14.92 11.18 A4.41354 4.41354 -180 0 0 10.51 6.77 A4.4141
						 4.4141 -180 0 0 6.1 11.18 ZM6.9 11.18 A3.61642 3.61642 0 0 1 10.51 7.57 A3.616 3.616 0 0 1 14.12 11.18 A3.616
						 3.616 0 0 1 10.51 14.79 A3.61642 3.61642 0 0 1 6.9 11.18 ZM10.85 0.5 L0 0.5 L0 17.99 L14.9 17.99 L14.9 17.66
						 A1825.35 1825.35 -180 0 0 13.6 16.17 L13.6 16.69 L1.3 16.69 L1.3 1.8 L9.71 1.8 L9.71 5.68 L13.6 5.68 L13.6
						 7.18 A5.08616 5.08616 0 0 1 14.9 8.67 L14.9 4.55 L10.85 0.5 ZM10.36 5.03 L10.36 1.96 L13.44 5.03 L10.36
						 5.03 Z"></path>
			</g>
		</svg>
	)
}

export const LandingSpinner = () => {
	return (
		<svg
			colorInterpolationFilters="sRGB"
			fill="none"
			fillRule="evenodd"
			height="4.77458in"
			strokeLinecap="square"
			strokeMiterlimit="3"
			viewBox="0 0 343.77 343.77"
			width="4.77458in"
		>
			<radialGradient id="a" cx="1" cy="1" r="1.4">
				<stop offset="0" stopColor="#ffe1a4" />
				<stop offset="1" stopColor="#d27e00" />
			</radialGradient>
			<g
				fill="url(#a)"
				transform="matrix(.70587157 -.70833984 .70833984 .70587157 -71.3209 101.112)"
			>
				<path d="m0 222.23a121.541 121.541-180 1 0 243.08 0z" />
				<path
					d="m0 222.23a121.541 121.541-180 1 0 243.08 0z"
					transform="matrix(-1 0 0 -1 243.082 444.457)"
				/>
			</g>
			<ellipse
				cx="171.8851"
				cy="171.8851"
				fill="#1c1c1c"
				rx="110.481"
				ry="110.481"
			/>
		</svg>
	);
}

export const Arrow = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
			<path d="M448 208v96c0 13.3-10.7 24-24 24H224v103.8c0 21.4-25.8 32.1-41 17L7 273c-9.4-9.4-9.4-24.6 0-34L183 63.3c15.1-15.1 41-4.4 41 17V184h200c13.3 0 24 10.7 24 24z" />
		</svg>
	);
}

export const ArrowUp = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className="arrow-up" viewBox="0 0 448 512">
			<path d="M448 208v96c0 13.3-10.7 24-24 24H224v103.8c0 21.4-25.8 32.1-41 17L7 273c-9.4-9.4-9.4-24.6 0-34L183 63.3c15.1-15.1 41-4.4 41 17V184h200c13.3 0 24 10.7 24 24z" />
		</svg>
	);
}


export const ArrowDown = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className="arrow-down" viewBox="0 0 448 512">
			<path d="M448 208v96c0 13.3-10.7 24-24 24H224v103.8c0 21.4-25.8 32.1-41 17L7 273c-9.4-9.4-9.4-24.6 0-34L183 63.3c15.1-15.1 41-4.4 41 17V184h200c13.3 0 24 10.7 24 24z" />
		</svg>
	);
}

export const RefreshArrow = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
			<path d="M9 13.5c-2.49 0-4.5-2.01-4.5-4.5S6.51 4.5 9 4.5c1.24 0 2.36.52 3.17 1.33L10 8h5V3l-1.76 1.76C12.15 3.68 10.66 3 9 3 5.69 3 3.01 5.69 3.01 9S5.69 15 9 15c2.97 0 5.43-2.16 5.9-5h-1.52c-.46 2-2.24 3.5-4.38 3.5z" />
		</svg>
	);
}

export const DoubleArrow = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
			<path d="M12 6v3l4-4-4-4v3c-4.42 0-8 3.58-8 8 0 1.57.46 3.03 1.24 4.26L6.7 14.8c-.45-.83-.7-1.79-.7-2.8 0-3.31 2.69-6 6-6zm6.76 1.74L17.3 9.2c.44.84.7 1.79.7 2.8 0 3.31-2.69 6-6 6v-3l-4 4 4 4v-3c4.42 0 8-3.58 8-8 0-1.57-.46-3.03-1.24-4.26z" />
		</svg>
	)
}

export const FreeformDraw = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" focusable="false" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
			<path d="M12 2c5.5 0 10 3.13 10 7c0 3.26-3.19 6-7.5 6.78v-.28a5 5 0 0 0-.29-1.69C17.55 13.21 20 11.28 20 9c0-2.76-3.58-5-8-5S4 6.24 4 9c0 1.19.67 2.29 1.79 3.15c-.44.49-.79 1.06-1.01 1.7C3.06 12.59 2 10.88 2 9c0-3.87 4.5-7 10-7M9.5 12c1.93 0 3.5 1.57 3.5 3.5c0 1.9-1.5 3.45-3.4 3.5c-.21.36-.42 1 .23 1.68c1.17 1.2 3.45-.96 6.56-.97c2.04-.01 3.64.26 3.64.26s1.05.13.94 1.07c-.11.93-1.06.93-1.06.93c-.38-.04-1.88-.39-3.69-.29c-1.81.09-2.75.73-3.66 1.01c-.9.28-2.65.69-4.26-.64c-1.33-1.09-.84-2.94-.63-3.55a3.55 3.55 0 0 1-1.67-3C6 13.57 7.57 12 9.5 12m0 2c-.83 0-1.5.67-1.5 1.5S8.67 17 9.5 17s1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5z" fill="#626262" />
		</svg>
	)
}

export const OrangeCheck = () => {
	return (
		<svg colorInterpolationFilters="sRGB" fill="none" fillRule="evenodd" height=".194444in" strokeLinecap="square" strokemeterlimit="3" viewBox="0 0 14 14" width=".194444in" xmlns="http://www.w3.org/2000/svg">
			<path d="m.25.25h13.5v13.5h-13.5z" fill="#ffbd59" stroke="#262626" strokeLinecap="round" strokeLinejoin="round" strokeWidth=".5" />
			<path d="m0 10.86a20.0714 13.7914 0 0 1 4.57 3.14 14.9361 11.0889 90 0 1 5.48-7.39l-1.12-1.66a13.9552 15.9596-180 0 0 -5.16 5.91 15.7595 9.38241-180 0 0 -3.01-1.79z" fill="#000" transform="translate(1.97266 -2.5)" />
		</svg>
	)
}

export const OrangeUnchecked = () => {
	return (
		<svg colorInterpolationFilters="sRGB" fill="none" fillRule="evenodd" height=".197917in" strokeLinecap="square" strokemeterlimit="3" viewBox="0 0 14.25 14.25" width=".197917in" xmlns="http://www.w3.org/2000/svg">
			<path d="m.375.375h13.5v13.5h-13.5z" fill="#fff" stroke="#262626" strokeLinecap="round" strokeLinejoin="round" strokeWidth=".75" />
		</svg>
	)
}

export const OrangeRadio = () => {
	return (
		<svg colorInterpolationFilters="sRGB" fill="none" fillRule="evenodd" height=".197917in" strokeLinecap="square" strokemeterlimit="3" viewBox="0 0 14.1797 14.25" width=".19694in" xmlns="http://www.w3.org/2000/svg">
			<ellipse cx="7.1" cy="7.1" fill="#ffbd59" rx="6.8" ry="6.8" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth=".5" />
			<path transform="translate(2.9,-3.3)" d="M 0 11.6 a 16.8 11.5 0 0 1 3.8 2.6 a 12.5 9.3 90 0 1 4.6 -6.2 l -0.9 -1.4 a 11.7 13.4 -180 0 0 -4.3 4.9 a 13.2 7.9 -180 0 0 -2.5 -1.5 z" fill="#000000" />
		</svg>
	)
}

export const OrangeRadioUnchecked = () => {
	return (
		<svg colorInterpolationFilters="sRGB" fill="none" fillRule="evenodd" height=".197917in" strokeLinecap="square" strokemeterlimit="3" viewBox="0 0 14.1797 14.25" width=".19694in" xmlns="http://www.w3.org/2000/svg">
			<ellipse cx="7.08984" cy="7.125" fill="#fff" rx="6.71484" ry="6.75" stroke="#262626" strokeLinecap="round" strokeLinejoin="round" strokeWidth=".75" />
		</svg>
	)
}

export const DrawLine = () => {
	return (
		<svg id="glyphicons-basic" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
			<path id="vector-path-line" d="M28,3H23a1,1,0,0,0-1,1V7.87891L7.87891,22H4a1,1,0,0,0-1,1v5a1,1,0,0,0,1,1H9a1,1,0,0,0,1-1V24.12109L24.12109,10H28a1,1,0,0,0,1-1V4A1,1,0,0,0,28,3ZM8,27H5V24H8ZM27,8H24V5h3Z" />
		</svg>
	)
}

export const DrawPoly = () => {
	return (
		<svg id="glyphicons-basic" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
			<path id="vector-path-pentagon" d="M29,11H24.23816L19,7.19043V4a1,1,0,0,0-1-1H13a1,1,0,0,0-1,1V7.37915L7.47375,11H3a1,1,0,0,0-1,1v5a1,1,0,0,0,1,1H5.08551l1.68231,5.04688A.98576.98576,0,0,0,6,24v5a1,1,0,0,0,1,1h5a1,1,0,0,0,1-1V28h6v1a1,1,0,0,0,1,1h5a1,1,0,0,0,1-1V24a.9857.9857,0,0,0-.76776-.95312L26.91461,18H29a1,1,0,0,0,1-1V12A1,1,0,0,0,29,11ZM7,16H4V13H7ZM14,5h3V8H14ZM11,28H8V25h3Zm8-4v1H13V24a1,1,0,0,0-1-1H9.91443L8.23224,17.95312A.9857.9857,0,0,0,9,17V13.62109L13.52594,10h4.236L23,13.80957V17a.98577.98577,0,0,0,.7677.95312L22.08557,23H20A1,1,0,0,0,19,24Zm5,4H21V25h3Zm4-12H25V13h3Z" />
		</svg>
	)
}

export const Ruler = () => {
	return (
		<svg id="glyphicons-basic" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
			<path id="rulers" d="M28,5v5a1,1,0,0,1-1,1H11V27a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V26H5.5a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H4V22H5.5a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H4V18H5.5a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H4V14H5.5a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H4V10H5.5A.5.5,0,0,0,6,9.5v-1A.5.5,0,0,0,5.5,8H4V6H5.5A.5.5,0,0,0,6,5.5V4H8V5.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V4h2V5.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V4h2V5.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V4h2V5.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V4h2V5.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V4h1A1,1,0,0,1,28,5Z" />
		</svg>
	)
}

export const StepBack = () => {
	return (
		<svg id="glyphicons-basic" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
			<path id="step-back" d="M28,21.5V23a1,1,0,0,1-1,1H24a1,1,0,0,1-1-1V21.5A4.5,4.5,0,0,0,18.5,17H13v2.07935a.9999.9999,0,0,1-1.57349.81909l-6.54187-4.5791a1.0002,1.0002,0,0,1,0-1.63868l6.54187-4.57934A1.00006,1.00006,0,0,1,13,9.92065V12h5.5A9.50008,9.50008,0,0,1,28,21.5Z" />
		</svg>
	)
}


export const LockedLock = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
			<path d="M18 10v-4c0-3.313-2.687-6-6-6s-6 2.687-6 6v4h-3v14h18v-14h-3zm-10 0v-4c0-2.206 1.794-4 4-4s4 1.794 4 4v4h-8z" />
		</svg>
	)
}

export const UnlockedLock = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
			<path d="M12 10v-4c0-3.313-2.687-6-6-6s-6 2.687-6 6v3h2v-3c0-2.206 1.794-4 4-4s4 1.794 4 4v4h-4v14h18v-14h-12z" />
		</svg>
	)
}
// export const Home = () => {
// 	return (
// 		<svg id="glyphicons-basic" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
// 			<path id="home" d="M27.10482,17H24V27.5a.5.5,0,0,1-.5.5h-4a.5.5,0,0,1-.5-.5v-5a.5.5,0,0,0-.5-.5h-5a.5.5,0,0,0-.5.5v5a.5.5,0,0,1-.5.5h-4a.5.5,0,0,1-.5-.5V17H4.89518a.785.785,0,0,1-.55508-1.34008L15.46967,4.53023a.75.75,0,0,1,1.06066,0L27.6599,15.65992A.785.785,0,0,1,27.10482,17Z" />
// 		</svg>
// 	)
// }

export const Home = () => {
	return (
		<svg class="MuiSvgIcon-root jss81" focusable="false" viewBox="0 0 24 24" ><path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z"></path></svg>
	)
}

export const Menu = () => {
	return (
		<svg focusable="false" viewBox="0 0 24 24" >
			<path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z">
			</path>
		</svg>
	)
}

export const Chart = () => {
	return (
		<svg class="MuiSvgIcon-root jss81" focusable="false" viewBox="0 0 24 24" >
			<path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zM9 17H7v-7h2v7zm4 0h-2V7h2v10zm4 0h-2v-4h2v4z">
			</path>
		</svg>
	)
}

export const Grid = () => {
	return (
		<svg className="MuiSvgIcon-root jss81" focusable="false" viewBox="0 0 24 24">
			<path d="M4 14h4v-4H4v4zm0 5h4v-4H4v4zM4 9h4V5H4v4zm5 5h12v-4H9v4zm0 5h12v-4H9v4zM9 5v4h12V5H9z">
			</path>
		</svg>
	)
}

export const ChartGrid = () => {
	return (
		<svg className="MuiSvgIcon-root jss81" focusable="false" viewBox="0 0 24 24">
			<path d="M22 13h-8v-2h8v2zm0-6h-8v2h8V7zm-8 10h8v-2h-8v2zm-2-8v6c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V9c0-1.1.9-2 2-2h6c1.1 0 2 .9 2 2zm-1.5 6l-2.25-3-1.75 2.26-1.25-1.51L3.5 15h7z">
			</path>
		</svg>
	)
}

export const MapLayer = () => {
	return (
		<svg className="MuiSvgIcon-root jss81" focusable="false" viewBox="0 0 24 24">
			<path d="M11.99 18.54l-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27-7.38 5.74zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16z">
			</path>
		</svg>
	)
}

export const RecurrenceIcon = () => {
	return (
		// <svg width="24px" height="24px" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
		// 	<path d="M4.5 10.5V19.5H20.5V10.5M4.5 10.5V5.5H20.5V10.5M4.5 10.5H12.5H20.5M20.5 13.5H16.5M16.5 13.5H12.5M16.5 13.5V10.5M16.5 13.5V16.5M12.5 13.5H8.5M12.5 13.5V16.5M12.5 13.5V10.5M8.5 13.5H4.5M8.5 13.5V10.5M8.5 13.5V16.5M20.5 16.5H16.5M16.5 16.5H12.5M16.5 16.5V19.5M12.5 16.5H8.5M12.5 16.5V19.5M8.5 16.5H4.5M8.5 16.5V19.5M17.5 8V3M7.5 8V3" stroke="#121923" stroke-width="1.2" />
		// </svg>
		<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" className="recurrence-icon"
			width="24px" height="24px" viewBox="0 0 65 65">
			<path fill="none" stroke="#000000" strokeWidth="2" strokeMiterlimit="10" d="M24,38c0,4.418,3.582,9,8,9h4" />
			<polyline fill="none" stroke="#000000" strokeWidth="2" strokeLinejoin="bevel" strokeMiterlimit="10" points="33,51 36,47 
		33,43 "/>
			<path fill="none" stroke="#000000" strokeWidth="2" strokeMiterlimit="10" d="M42,38c0-4.418-3.582-9-8-9h-4" />
			<polyline fill="none" stroke="#000000" strokeWidth="2" strokeLinejoin="bevel" strokeMiterlimit="10" points="33,25 30,29 
		33,33 "/>
			<g>
				<line fill="none" stroke="#000000" strokeWidth="2" strokeMiterlimit="10" x1="46" y1="10" x2="18" y2="10" />
				<polyline fill="none" stroke="#000000" strokeWidth="2" strokeMiterlimit="10" points="12,10 1,10 1,58 63,58 63,10 52,10 	" />
				<rect x="12" y="6" fill="none" stroke="#000000" strokeWidth="2" strokeMiterlimit="10" width="6" height="8" />
				<rect x="46" y="6" fill="none" stroke="#000000" strokeWidth="2" strokeMiterlimit="10" width="6" height="8" />
			</g>
			<line fill="none" stroke="#000000" strokeWidth="2" strokeMiterlimit="10" x1="1" y1="18" x2="63" y2="18" />
		</svg>
	)
}

export const World = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" preserveAspectRatio="none">
			<path d="M0 0h24v24H0z" fill="none" />
			<path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 17.93c-3.95-.49-7-3.85-7-7.93 0-.62.08-1.21.21-1.79L9 15v1c0 1.1.9 2 2 2v1.93zm6.9-2.54c-.26-.81-1-1.39-1.9-1.39h-1v-3c0-.55-.45-1-1-1H8v-2h2c.55 0 1-.45 1-1V7h2c1.1 0 2-.9 2-2v-.41c2.93 1.19 5 4.06 5 7.41 0 2.08-.8 3.97-2.1 5.39z" />
		</svg>
	)
}

export const OpenLink = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" preserveAspectRatio="none">
			<path d="M0 0h24v24H0z" fill="none" />
			<path d="M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1zM8 13h8v-2H8v2zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4V17h4c2.76 0 5-2.24 5-5s-2.24-5-5-5z" />
		</svg>
	)
}

export const KanbanCard = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" preserveAspectRatio="none">
			<path d="M0 0h24v24H0z" fill="none" />
			<path d="M19 5H5c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 12H5V7h14v10z" />
		</svg>
	)
}

export const CircleX = () => {
	return (
		<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="18px" height="18px"
			viewBox="0 0 247.574 247.574" >
			<g>
				<path d="M123.787,0.001C55.53,0.001,0,55.531,0,123.787s55.53,123.786,123.787,123.786s123.787-55.53,123.787-123.786
		S192.044,0.001,123.787,0.001z M123.787,232.573C63.802,232.573,15,183.772,15,123.787S63.802,15.001,123.787,15.001
		s108.787,48.801,108.787,108.786S183.773,232.573,123.787,232.573z"/>
				<path d="M133.047,123.786l42.316-58.429c2.43-3.355,1.68-8.044-1.675-10.474c-3.356-2.429-8.043-1.68-10.474,1.675L123.787,111
		l-39.43-54.441c-2.43-3.356-7.12-4.105-10.474-1.675c-3.354,2.43-4.104,7.119-1.675,10.474l42.317,58.429l-42.317,58.43
		c-2.43,3.355-1.68,8.044,1.675,10.474c1.33,0.963,2.868,1.426,4.394,1.426c2.322,0,4.613-1.076,6.08-3.101l39.43-54.442
		l39.43,54.442c1.467,2.025,3.757,3.101,6.08,3.101c1.525,0,3.064-0.463,4.393-1.426c3.355-2.43,4.105-7.119,1.675-10.474
		L133.047,123.786z"/>
			</g>
		</svg>
	)
}

export const TriangleLeft = () => {
	return (
		<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30px" height="30px"
			viewBox="0 0 386.258 386.258">
			<polygon points="96.879,193.129 289.379,386.258 289.379,0 " />
		</svg>
	)
}

export const TriangleRight = () => {
	return (
		<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30px" height="30px"
			viewBox="0 0 386.258 386.258">
			<polygon points="96.879,0 96.879,386.258 289.379,193.129 " />
		</svg>
	)
}

export const LoadingDotSpinner = () => {
	return (
		<svg width="48" height="48" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
			<g>
				<circle cx="12" cy="2.5" r="1.5" opacity=".14" /><circle cx="16.75" cy="3.77" r="1.5" opacity=".29" />
				<circle cx="20.23" cy="7.25" r="1.5" opacity=".43" /><circle cx="21.50" cy="12.00" r="1.5" opacity=".57" />
				<circle cx="20.23" cy="16.75" r="1.5" opacity=".71" /><circle cx="16.75" cy="20.23" r="1.5" opacity=".86" />
				<circle cx="12" cy="21.5" r="1.5" />
				<animateTransform attributeName="transform" type="rotate" calcMode="discrete" dur="0.75s" values="0 12 12;30 12 12;60 12 12;90 12 12;120 12 12;150 12 12;180 12 12;210 12 12;240 12 12;270 12 12;300 12 12;330 12 12;360 12 12" repeatCount="indefinite" />
			</g>
		</svg>
	)
}

export const CarBuilding = () => {
	return (
		<svg viewBox="0 0 24 24" >
			<path d="M20.57 10.66c-.14-.4-.52-.66-.97-.66h-7.19c-.46 0-.83.26-.98.66L10 14.77l.01 5.51c0 .38.31.72.69.72h.62c.38 0 .68-.38.68-.76V19h8v1.24c0 .38.31.76.69.76h.61c.38 0 .69-.34.69-.72l.01-1.37v-4.14l-1.43-4.11zm-8.16.34h7.19l1.03 3h-9.25l1.03-3zM12 17c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm8 0c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z"></path>
			<path d="M14 9h1V3H7v5H2v13h1V9h5V4h6z"></path>
			<path d="M5 11h2v2H5zm5-6h2v2h-2zM5 15h2v2H5zm0 4h2v2H5z"></path>
		</svg>
	)
}

export const Fence = () => {
	return (
		<svg id="glyphicons-basic" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
			<path id="fence" d="M28,15V12H26V9.28687a1.00007,1.00007,0,0,0-.152-.53L23.924,5.67847a.4999.4999,0,0,0-.848,0L21.152,8.75684a1.00007,1.00007,0,0,0-.152.53V12H18V9.28687a1.00007,1.00007,0,0,0-.152-.53L15.924,5.67847a.4999.4999,0,0,0-.848,0L13.152,8.75684a1.00007,1.00007,0,0,0-.152.53V12H10V9.28687a1.00007,1.00007,0,0,0-.152-.53L7.924,5.67847a.4999.4999,0,0,0-.848,0L5.152,8.75684a1.00007,1.00007,0,0,0-.152.53V12H3v3H5v5H3v3H5v3a1,1,0,0,0,1,1H9a1,1,0,0,0,1-1V23h3v3a1,1,0,0,0,1,1h3a1,1,0,0,0,1-1V23h3v3a1,1,0,0,0,1,1h3a1,1,0,0,0,1-1V23h2V20H26V15ZM13,20H10V15h3Zm8,0H18V15h3Z" />
		</svg>
	)
}

export const Barcode = () => {
	return (
		<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 122.88 97.04" >
			<g>
				<path d="M2.38,0h18.33v4.76H4.76V17.2H0V2.38C0,1.07,1.07,0,2.38,0L2.38,0z M17.92,16.23h8.26v64.58h-8.26V16.23L17.92,16.23z M69.41,16.23h5.9v64.58h-5.9V16.23L69.41,16.23z M57.98,16.23h4.42v64.58h-4.42V16.23L57.98,16.23z M33.19,16.23h2.51v64.58h-2.51 V16.23L33.19,16.23z M97.59,16.23h7.37v64.58h-7.37V16.23L97.59,16.23z M82.32,16.23h8.26v64.58h-8.26V16.23L82.32,16.23z M42.71,16.23h8.26v64.58h-8.26V16.23L42.71,16.23z M4.76,79.84v12.44h15.95v4.76H2.38C1.07,97.04,0,95.98,0,94.66V79.84H4.76 L4.76,79.84z M103.4,0h17.1c1.31,0,2.38,1.07,2.38,2.38V17.2h-4.76V4.76H103.4V0L103.4,0z M122.88,79.84v14.82 c0,1.31-1.07,2.38-2.38,2.38h-17.1v-4.76h14.72V79.84H122.88L122.88,79.84z" />
			</g>
		</svg>
	)
}

export const QuickEdit = () => {
	return (
		<svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc" focusable="false" ariaHidden="true" viewBox="0 0 24 24" dataTestid="FilterNoneIcon">
			<path d="M3 5H1v16c0 1.1.9 2 2 2h16v-2H3V5zm18-4H7c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V3c0-1.1-.9-2-2-2zm0 16H7V3h14v14z">
			</path>
		</svg>
	)
}

export const FilterDropdown = () => { //Burger icon
	return (
		<svg id="glyphicons-basic-burger" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" height="20px" width="20px">
			<path fill="white" id="menu" d="M27,23v2a1,1,0,0,1-1,1H6a1,1,0,0,1-1-1V23a1,1,0,0,1,1-1H26A1,1,0,0,1,27,23ZM26,6H6A1,1,0,0,0,5,7V9a1,1,0,0,0,1,1H26a1,1,0,0,0,1-1V7A1,1,0,0,0,26,6Zm0,8H6a1,1,0,0,0-1,1v2a1,1,0,0,0,1,1H26a1,1,0,0,0,1-1V15A1,1,0,0,0,26,14Z" />
		</svg>
	)
}

export const FilterX = () => {
	return (
		<svg id="glyphicons-basic-filter-x" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" height="20px" width="20px">
			<path fill="red" id="square-remove" d="M24.5,4H7.5A3.50424,3.50424,0,0,0,4,7.5v17A3.50424,3.50424,0,0,0,7.5,28h17A3.50424,3.50424,0,0,0,28,24.5V7.5A3.50424,3.50424,0,0,0,24.5,4ZM21.30328,18.47485a.49991.49991,0,0,1,0,.707L19.182,21.30322a.5.5,0,0,1-.70716,0L16,18.82837l-2.47485,2.47485a.5.5,0,0,1-.70716,0l-2.12127-2.12134a.49991.49991,0,0,1,0-.707L13.17157,16l-2.47485-2.47485a.50021.50021,0,0,1,0-.70728L12.818,10.69678a.5.5,0,0,1,.70716,0L16,13.17139l2.47485-2.47461a.5.5,0,0,1,.70716,0l2.12127,2.12109a.50021.50021,0,0,1,0,.70728L18.82843,16Z" />
		</svg>
	)
}

export const Folder = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" height="24px" width="24px">
			<path d="M19.5 21a3 3 0 0 0 3-3v-4.5a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3V18a3 3 0 0 0 3 3h15ZM1.5 10.146V6a3 3 0 0 1 3-3h5.379a2.25 2.25 0 0 1 1.59.659l2.122 2.121c.14.141.331.22.53.22H19.5a3 3 0 0 1 3 3v1.146A4.483 4.483 0 0 0 19.5 9h-15a4.483 4.483 0 0 0-3 1.146Z" />
		</svg>

	)
}
